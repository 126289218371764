import React from 'react';
import { Link } from 'react-router-dom';

import Header from '../../components/Header';
import Footer from '../../components/Footer';
import img_Lobe from '../../assets/img/otherspace-lobe-img.jpg';
import img_Tape4 from '../../assets/img/otherspace-tape_4.jpg';
import img_Still from '../../assets/img/otherspace-still.jpg'
import img_Still2 from '../../assets/img/otherspace-still2.jpg'
import img_Mock from '../../assets/img/otherspace-mockup_web.jpg';

import img_CCALogo from '../../assets/img/logo-CCA.jpg';
import img_MusikfondsLogo from '../../assets/img/logo-Musikfonds.jpg';
import img_LobeLogo from '../../assets/img/logo-Lobe.png';
import img_MonomLogo from '../../assets/img/logo-Monom.png';

function Otherspace() {

  const headerProps = {
    title: 'otherspace',
    style: 'default'
  }

  return (
    <>
      <Link to='/' title='volver'>
        <i className='fas fa-long-arrow-alt-left arrow-icon'></i>
      </Link>
      
      <Header title={headerProps.title} style={headerProps.style} />

      <main>
        <article className='works'>
          <div>
            <p className='text'> Created in collaboration with sound artist perila, and designer Kristen Stokes, otherspace is a generative, immersive and audio-reactive sound experience that shifts perception of ordinary sound environments into unique and ever-changing soundscapes. As a portable sound work for smartphones, it encourages deep listening as we navigate the sound spaces that feature in daily life, and supports developing a mindful relationship with these external worlds. </p>
            <br/>
            <p className='text'> otherspace records your audio surroundings and plays back interesting moments as compositional elements within four generative ‘spaces’ composed by sound artist perila: niama, tarak, istra, keari. Each space is it’s own immersive sound experience with unique moods and spatial dynamics that will unfold in response to a listener’s sound environment. </p>
            <br/>
            <p className='text'> Recordings of otherspace being used in Morocco, Canada, Italy, and Germany were compiled in celebration of the release, and to document the work in a fixed media archive that will outlast the lifespan of a contemporary smartphone app. These recordings are released digitally and as a limited edition cassette. </p>
            <br/>
            <p className='text'> Audio generated by otherspace is rendered in binaural spatial audio - headphone listening is highly recommended. </p>
            <br/>
            <p className='text'> Release date: March 2nd, 2025 </p>
            <p className='text'> Formats: Android + iOS app, cassette tape, digital </p>
            <br/>

            <div>
            <a className='landing-link' href='https://play.google.com/store/apps/details?id=com.computerkunst.otherspace' target='_blank' rel='noopener noreferrer'>
                Android App
            </a>
            </div>
            <div>
            <a className='landing-link' href='https://apps.apple.com/us/app/otherspace/id6683307596' target='_blank' rel='noopener noreferrer'>
                iOS App
            </a>
            </div>
            <div>
            <a className='landing-link' href='https://other-space.bandcamp.com/album/otherspace' target='_blank' rel='noopener noreferrer'>
                Bandcamp Release
            </a>
            </div>
            <div>
            <a className='landing-link' href='https://otherspace.cc' target='_blank' rel='noopener noreferrer'>
                Website
            </a>
            </div>

            <div>
            <a className='landing-link' href='https://lobestudio.ca/jordan-and-perila' target='_blank' rel='noopener noreferrer'>
              Lobe residency, 
            </a>
            <a className='landing-link' href='https://lobestudio.ca/new-events/lobe-artist-residency-series-jordan-juras-and-perila' target='_blank' rel='noopener noreferrer'>
                &nbsp;performance
            </a>
            
            </div>
            <br/>

            <img className='works-img-med' alt='otherspace mock' src={img_Still2}></img>
            <img className='works-img-med' alt='otherspace mock' src={img_Mock}></img>
            <img className='works-img-med' alt='otherspace promo img' src={img_Tape4}></img>
            <img className='works-img-med' alt='otherspace lobe residency' src={img_Lobe}></img>
            <img className='works-img-med' alt='otherspace promo img' src={img_Still}></img>

            <br/>

            <br/>
                        
            <img className='logo-small' alt='cca logo' src={img_CCALogo}></img>
            <img className='logo-large' alt='musikfonds logo' src={img_MusikfondsLogo}></img>
            <img className='logo-large' alt='lobe logo' src={img_LobeLogo}></img>
            <img className='logo-small' alt='monom logo' src={img_MonomLogo}></img>

          </div>
        </article>
      </main>

      <Footer hidden='hidden' position='footer-position' />
    </>
  );
}

export default Otherspace;
